<template>
  <div class="rating-line">
    <div :class="['rating-top', parseInt(_.get(review, 'rating', 0)) > 0 ? '' : 'no-rating']">
      <div v-if="parseInt(_.get(review, 'rating', 0)) > 0" :style="{backgroundImage: 'url(/img/rating.png)'}" :class="['rating-sprite rating-' + parseInt(_.get(review, 'rating', 0)).toFixed(0) ]"></div>
      <p class="text">{{ $t('reviews.written_by', { reviewer: review.reviewer, date: $options.filters.date( new Date(review.created_at)) }) }}</p>
      <b-badge v-if="_.get(review, 'acquisition_verified', false) === true" variant="success">
        {{ $t('reviews.acquisition_verified') }}
      </b-badge>
    </div>

    <p v-if="!_.isNil(review.review)" class="text">{{ review.review }}</p>
    <p v-else class="text"><i>{{ $t('reviews.no_review') }}</i></p>

    <div v-if="review.replies" class="replies">
      <b-row v-for="(reviewReply, rIndex) in review.replies" :key="rIndex">
        <b-col>
          <user-review :review="reviewReply"></user-review>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      review: {
        type: Object,
        default: function()
        {
          return {};
        }
      }
    }
  };
</script>
