<template>
  <div class="second-header flex-column flex-lg-row">
    <span class="d-flex">{{ $t('header.welcome') }}
      <span class="name">&nbsp;
        <nuxt-link :to="{name: 'my-account' }" class="yellow">
          {{ $auth.user.client_name || $auth.user.name || 'pe Yummy' }}
        </nuxt-link>
      </span>
    </span>
    <button type="button" class="btn logout btn-outline" @click="logout">{{ $t('logout') }}</button>
  </div>
</template>
<script>
  export default {
    methods: {
      logout() {
        this.$auth.logout();
        this.$router.replace("/");
      }
    }
  };
</script>
