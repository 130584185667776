<template>
  <b-row>
    <b-col class="my-account orders">
      <div class="account-card">
        <h3 class="block-title text-center">{{ $t('header.welcome') }} {{ $auth.user.client_name || $auth.user.name || '' }}</h3>
        <h5 class="block-subtitle">{{ $t('myAccount.myOrders.subTitle') }}</h5>
        <b-tabs content-class="">
          <b-tab v-if="currentOrders.length" active>
            <template v-slot:title>
              {{ $t('myAccount.myOrders.activeOrders') }}<span>{{ currentOrders.length }}</span>
            </template>
            <ul class="tab-list">
              <li v-for="(order, index) in currentOrders" :key="index">
                <Order :order="order"></Order>
              </li>
            </ul>
          </b-tab>
          <b-tab v-if="pastOrders.length">
            <template v-slot:title>
              {{ $t('myAccount.myOrders.pastOrders') }}<span>{{ pastOrders.length }}</span>
            </template>
            <ul class="tab-list">
              <li v-for="(order, index) in pastOrders" :key="index">
                <Order :order="order"></Order>
              </li>
            </ul>
          </b-tab>
        </b-tabs>
        <div class="helper mt-3">
          <a href="" class="link"><i class="icon-help"></i>{{ $t('myAccount.myOrders.helpLink') }}</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    data: function() {
      return {
        orders: [],
        page: undefined
      }
    },
    computed: {
      currentOrders () {
        /*
        Possible statuses

        'NEW',
        'ACCEPTED',
        'IN_PREPARATION',
        'SENT',
        'DELIVERED',
        'CANCELED'
         */
        return this.orders.filter(order => order.status !== 'DELIVERED' && order.status !== 'CANCELED')
      },
      pastOrders () {
        return this.orders.filter(order => order.status === 'DELIVERED' || order.status === 'CANCELED')
      }
    },
    mounted () {
      this.getPageHead().then((res) => {
        this.page = res
      })
      let fields = [
        'id',
        'phone',
        'address_name',
        'county_id',
        'city_id',
        'street_id',
        'street_no',
        'street_ap',
        'remarks',
        'order_reception',
        'order_sent',
        'order_delivered',
        'delivery_time',
        'order_canceled',
        'cancel_message',
        'status',
        'created_at',
        'updated_at',
        'products.quantity',
        'products.amount_with_vat',
        'products.vat_id',
        'products.is_free_product',
        'products.cart_rule_details',
        'payments.id',
        'payments.payment_type_id',
        'payments.amount',
        'payments.created_at',
        'payments.updated_at'
      ]
      this.$api.getOrders(fields, [], [], {}, [['-created_at']]).then(response => {
        this.orders = this._.get(response, 'data.data');
      })
    },
    head() {
      var meta = [
        { hid: 'og:title', property: 'og:title', content: this.page?.title },
        { hid: 'og:type', property: 'og:type', content: 'website'}
      ];
      if(this.page?.meta_description) {
        meta.push({ hid: 'description', name: 'description', content: this.page?.meta_description })
        meta.push({ hid: 'og:description', property: 'og:description', content: this.page?.meta_description })
      }
      if(this.page?.og_image) {
        meta.push({ hid: 'og:image', property: 'og:image', content: this.page?.og_image })
      }
      return {
        title: this.page?.title,
        meta: meta
      }
    }
  };
</script>
