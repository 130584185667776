<template>
  <label class="kg-checkbox">
    <input v-model="checked" value="true" unchecked-value="false" type="checkbox" />
    <div class="ico"></div>
  </label>
</template>
<script>
  export default {
    props: {
      checkedProp: {
        type: Boolean,
        default: function () {
          return false
        }
      }
    },
    data() {
      return {
        checked: false
      }
    },
    watch: {
      checked: {
        handler (val) {
          this.$emit('change', val)
        }
      },
      checkedProp: {
        handler (val) {
          this.checked = val
        }
      }
    },
    mounted() {
      this.checked = this.checkedProp
    },
  }
</script>
