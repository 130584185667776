<template>
  <nuxt-link :to="{ name: 'restaurant-detail', params: { slug: restaurant.slug } }" :class="['card restaurant-item', !isAvailable ? 'disabled' : '' ]">
    <div class="restaurant-detail">
      <div class="comp-image">
        <span v-if="restaurant.delivery_time" class="tlivrare">
          <i class="icon-timer" />
          {{ restaurant.settings.ordergroup_delivery_time }} {{ restaurant.delivery_time }} {{ $t('restaurant.min') }}
        </span>
        <img v-if="restaurant.cover_filename" :src="getImage('images', restaurant.cover_filename, 456, 456, 'crop')" class="brand" />
        <img v-else src="/img/norestaurant.png" class="brand" />
      </div>
      <div class="content-wrap">
        <h3 :class="['title',restaurant.cover_filename ? 'cwhite' :'' ]">{{ restaurant.display_name }}</h3>
        <p v-if="!isAvailable" class="text-danger">{{ $t('restaurantDetail.temporarily_disabled') }}</p>
        <template v-if="isAvailable">
          <div v-if="restaurant.ratingsummary.avg" class="rating-line">
            <div :style="rating" :class="['rating-sprite rating-'+restaurant.ratingsummary.avg.toFixed(0)]" />
            <div class="avgblock"><strong class="avg">{{ restaurant.ratingsummary.avg.toFixed(1) }}</strong>({{ restaurant.ratingsummary.count }})</div>
          </div>
          <div v-else class="rating-line">
            <div :style="rating" class="rating-0 rating-sprite">
            </div>
            <div class="avgblock">{{ restaurant.ratingsummary.count === 0 ? '(0)' : restaurant.ratingsummary.count }}</div>
          </div>

          <ul>
            <li v-if="restaurant.minimum_delivery_amount">
              {{ $t('minOrderAmountShort') }} {{ restaurant.minimum_delivery_amount | price }}
              <span v-if="workhours.start && workhours.end"> / {{ workhours.start }} - {{ workhours.end }}</span>
            </li>
          </ul>
          <div class="btn-line">
            <span class="btn btn-primary">{{ $t('details') }}</span>
          </div>
        </template>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
  export default {
    props: {
      restaurant: {
        type: Object,
        default: function()
        {
          return {};
        }
      }
    },
    data: function (){
      return {
        workhours: {},
        rating: { backgroundImage:"url(/img/rating.png)" }
      }
    },
    computed: {
      deliveryStatus () {
        var statuses = [];
        if (this.restaurant.offers_delivery) statuses.push(this.$t('delivery'))
        if (this.restaurant.offers_pickup) statuses.push(this.$t('pickup'))
        return statuses.join(' / ')
      },
      isAvailable () {
        return this.restaurant.enable_order
      }
    },
    mounted() {
      this.workhours = {
        start: this._.get(this.restaurant.settings, `workhour_${this.getDayName(undefined, 'en-US').toLowerCase()}_a`),
        end: this._.get(this.restaurant.settings, `workhour_${this.getDayName(undefined, 'en-US').toLowerCase()}_b`)
      }
    }
  };
</script>
