<template>
  <b-row>
    <b-col class="my-account">
      <div class="account-card fixed-forms">
        <h3 class="block-title text-center">{{ $t('header.welcome') }} {{ $auth.user.client_name || $auth.user.name || '' }}</h3>
        <div class="form-group">
          <label>{{ $t('profile_page.email') }}</label>
          <input id="email" v-model="user.email" type="email" class="form-control" :placeholder="$t('profile_page.email')" />
        </div>
        <div class="form-group">
          <label>{{ $t('profile_page.phone') }}</label>
          <input id="phone" v-model="user.phone" type="text" class="form-control" :placeholder="$t('profile_page.phone')" />
        </div>
        <div class="form-group">
          <label>{{ $t('profile_page.client_name') }}</label>
          <input id="client_name" v-model="user.client_name" type="text" class="form-control" :placeholder="$t('profile_page.client_name')" />
        </div>
        <div class="form-group">
          <label>{{ $t('profile_page.birthday') }}</label>
          <input id="birthday" v-model="user.birthday" type="date" class="form-control" :placeholder="$t('profile_page.birthday')" />
        </div>
        <div :class="['form-group pass-swithcer', toggleVisibility ? 'switched' : '']">
          <label>{{ $t('registerForm.input.password') }}</label>
          <input v-model="user.password" :placeholder="($t('registerForm.input.password'))" type="password" name="password" class="form-control ipassword" />
          <input v-model="user.password" :placeholder="($t('registerForm.input.password'))" type="text" name="password" class="form-control itext" />
          <b-btn class="swither" @click="toggleVisibility = !toggleVisibility"><i class="icon-visibility"></i></b-btn>
        </div>
        <div :class="['form-group pass-swithcer', toggleVisibility2 ? 'switched' : '']">
          <label>{{ $t('registerForm.input.passwordConfirm') }}</label>
          <input v-model="user.confirm_password" :placeholder="($t('registerForm.input.passwordConfirm'))" type="password" name="confirm_password" class="form-control ipassword" />
          <input v-model="user.confirm_password" :placeholder="($t('registerForm.input.passwordConfirm'))" type="text" name="confirm_password" class="form-control itext" />
          <b-btn class="swither" @click="toggleVisibility2 = !toggleVisibility2"><i class="icon-visibility"></i></b-btn>
        </div>
        <div class="form-group mt-3">
          <button class="btn submit" @click="saveProfile">{{ $t('profile_page.submit') }}</button>
        </div>
        <div class="helper mt-5">
          <a href="#!" class="link"><i class="icon-help"></i>{{ $t('profile_page.helpLink') }}</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    data: function() {
      return {
        toggleVisibility: false,
        toggleVisibility2: false,
        user: {
          email: undefined,
          phone: undefined,
          client_name: undefined,
          birthday: undefined,
          password: undefined,
          confirm_password: undefined
        },
        page: undefined
      }
    },
    mounted () {
      this.getPageHead().then((res) => {
        this.page = res
      })
      this.loadProfile()
    },
    methods: {
      loadProfile() {
        return this.$api.getUser().then(response => {
          let data = this._.get(response, 'data')
          this.user.email = data?.email
          this.user.phone = data?.name
          this.user.client_name = data?.client_name
          this.user.birthday = data?.birthday
        })
      },
      saveProfile() {
        this.$api.updateUser(this.user).then((res) => {
          this.showSuccess(this.$t('general.messages.success'))
          this.loadProfile()
        }).catch((err) => {
          this.showError(err.response)
        })
      }
    },
    head() {
      var meta = [
        { hid: 'og:title', property: 'og:title', content: this.page?.title },
        { hid: 'og:type', property: 'og:type', content: 'website'}
      ];
      if(this.page?.meta_description) {
        meta.push({ hid: 'description', name: 'description', content: this.page?.meta_description })
        meta.push({ hid: 'og:description', property: 'og:description', content: this.page?.meta_description })
      }
      if(this.page?.og_image) {
        meta.push({ hid: 'og:image', property: 'og:image', content: this.page?.og_image })
      }
      return {
        title: this.page?.title,
        meta: meta
      }
    }
  };
</script>
