<template>
  <b-row>
    <b-col class="my-account">
      <ul class="account-card">
        <li v-for="(address, index) in addresses" :key="index" class="checkbox-address">
          <label v-if="allowSelect" class="custom-radio">
            <input v-model="selectedAddress" :value="address" type="radio" name="address">
            <span class="ico"></span>
          </label>
          <Address :address="address" @reload="getAddresses"></Address>
        </li>
        <li>
          <div class="add-address-btn-holder">
            <button class="btn uppercase btn-verify" @click="addAddress">{{ $t('addresses.addNewAddress') }}<i class="icon-add"></i></button>
          </div>
        </li>
      </ul>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    allowSelect: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data: function() {
    return {
      selectedAddress: undefined,
      addresses: []
    }
  },
  watch: {
    selectedAddress: {
      handler (val) {
        this.$emit('addressSelected', val)
      }
    }
  },
  mounted () {
    this.getAddresses();
  },
  methods:{
    addAddress() {
      this.addresses.push({
        formOpened: true,
        county_id: undefined,
        city_id: undefined,
        street_id: undefined
      })
    },
    getAddresses(){
      this.$api.getAddresses().then(response => {
        this.addresses = this._.get(response, 'data.data').map((address) => {
          address.formOpened = false
          return address
        })
      })
    }
  }
};
</script>
