<template>
  <address class="address-block d-flex flex-column">
    <div v-if="address.id" class="name d-block mr-auto">{{ address.name }}</div>
    <div v-if="address.id" class="wrapp d-flex flex-column flex-md-row">
      <div class="adress-wrap d-flex flex-column align-content-between">
        <div class="adress">
          {{ address.county_name }}
          {{ address.city_name ? ', ' : null }}{{ address.city_name ? address.city_name : null }}
          {{ address.street_name ? ', ' : null }}{{ address.street_name ? address.street_name : null }}
          {{ address.street_no ? 'nr.' : null }}{{ address.street_no ? address.street_no : null }}
          {{ address.street_ap ? ', ap.' : null }}{{ address.street_ap ? address.street_ap : null }}
        </div>
        <div>
          <span v-if="address.phone" class="phone">
            <i class="icon-phone"></i> {{ address.phone }}
          </span>
          <span v-if="address.created_at" class="ml-2 ml-lg-5 created">
            {{ $t('addresses.created') }} : {{ address.created_at.slice(0, 10) }}
          </span>
        </div>
      </div>
      <div class="btn-grupped d-flex ml-auto">
        <button type="button" class="btn edit btn-icon" @click="address.formOpened = !address.formOpened"><i class="icon-edit"></i></button>
        <button type="button" class="btn delete btn-icon" @click="deleteAddress"><i class="icon-delete"></i></button>
      </div>
    </div>
    <div v-if="address.formOpened" class="fixed-forms d-flex">
      <div class="edit-modal autocomplete">
        <button type="button" class="btn close btn-outline btn-icon" @click="closeForm"><i class="icon-close"></i></button>
        <h3 class="title">{{ $t('addresses.formTitle') }}</h3>
        <div class="form-group">
          <label>{{ $t('addresses.form.name') }}</label>
          <input v-model="address.name" type="text" name="name" class="form-control" />
        </div>
        <div class="form-group">
          <label>{{ $t('addresses.form.phone') }}</label>
          <input v-model="address.phone" type="text" name="phone" class="form-control" />
        </div>
        <div class="form-group select auto">
          <label>{{ $t('addresses.form.county') }}</label>
          <v-select :options="availableCounties" :value="selectedCounty" :clearable="false" label="name" @input="(val) => address.county_id = val.id" />
        </div>
        <div :class="['form-group select auto', address.county_id > 0 ? '' : 'disabled']">
          <label>{{ $t('addresses.form.city') }}</label>
          <v-select :options="availableCities" :value="selectedCity" :clearable="false" label="name" @input="(val) => address.city_id = val.id" />
        </div>
        <div :class="['form-group select auto', address.city_id > 0 ? '' : 'disabled']">
          <label>{{ $t('addresses.form.streetSelect') }}</label>
          <v-select :options="availableStreets" :value="selectedStreet" :clearable="false" label="name" @input="(val) => address.street_id = val.id" />
        </div>
        <div :class="['form-group address', address.city_id > 0 ? '' : 'disabled']">
          <input v-model="address.street_no" :placeholder="$t('addresses.form.number')" type="text" name="nr" class="form-control" />
          <input v-model="address.street_ap" :placeholder="$t('addresses.form.appartment')" type="text" name="ap" class="form-control" />
        </div>
        <div class="form-group mb-0">
          <button type="submit" class="btn submit" @click="createOrUpdateAddress">{{ $t('addresses.save') }}</button>
        </div>
      </div>
    </div>
  </address>
</template>
<script>
  export default {
    props: {
      address: {
        type: Object,
        default: function()
        {
          return {};
        },
      }
    },
    data:function(){
      return {
        availableCounties: [],
        availableCities: [],
        availableStreets: [],
        reverseGeoCode: undefined
      }
    },
    computed: {
      selectedCounty () {
        return this.availableCounties.find((county) => county.id === this.address.county_id)
      },
      selectedCity () {
        return this.availableCities.find((city) => city.id === this.address.city_id)
      },
      selectedStreet () {
        return this.availableStreets.find((street) => street.id === this.address.street_id)
      }
    },
    watch: {
      'address.formOpened': {
        handler (val) {
          if (val && this.availableCounties.length < 1) {
            this.loadCounties().then(() => {
              if (this.address.county_id !== undefined) {
                this.getCitiesByCountyId(this.address.county_id).then(() => {
                  if (this.address.city_id !== undefined) {
                    this.getStreetsByCityId(this.address.city_id)
                  }
                })
              }
            })
          }
        }
      },
      'address.county_id': {
        handler (val) {
          this.getCitiesByCountyId(val)
        }
      },
      'address.city_id': {
        handler (val) {
          this.getStreetsByCityId(val)
        }
      }
    },
    mounted() {
      if(this.address.county_id === undefined) {
        this.loadCounties().finally(() => {
          this.getPreciseDeviceLocation().then((response) => {
            this.reverseGeoCode = this._.get(response, 'data')
            if(this._.get(this.reverseGeoCode, 'county_id') !== undefined) this.address.county_id = this.reverseGeoCode.county_id
            if(this._.get(this.reverseGeoCode, 'city_id') !== undefined) this.address.city_id = this.reverseGeoCode.city_id
            if(this._.get(this.reverseGeoCode, 'street_id') !== undefined) this.address.street_id = this.reverseGeoCode.street_id
          })
        })
      }
    },
    methods: {
      deleteAddress(){
        this.$api.deleteAddress(this.address.id).then(()=>{
          this.showSuccess(this.$t('general.messages.success'));
          this.$emit('reload');
        }).catch( (e) => {
          this.showError(this.$t('general.messages.error'));
        });
      },
      createOrUpdateAddress(){
        if (this.address.id) {
          this.$api.updateAddress(this.address.id, this.address).then((response) => {
            this.closeForm()
            this.showSuccess(this.$t('general.messages.success'));
          }).catch((e) => {
            this.showError(e.response);
          });
        } else {
          this.$api.createAddress(this.address).then((response) => {
            this.closeForm()
            this.showSuccess(this.$t('general.messages.success'));
          }).catch((e) => {
            this.showError(e.response);
          });
        }
      },
      closeForm (reload = true) {
        this.address.formOpened = false;
        if(reload) this.$emit('reload');
      },
      loadCounties() {
        return this.$api.getCounties().then((counties) => {
          this.availableCounties = this._.get(counties, 'data.data', [])
        })
      },
      getCitiesByCountyId (countyId) {
        return this.$api.getCitiesByCountyId(countyId).then((cities) => {
          this.availableCities = this._.get(cities, 'data', [])
        })
      },
      getStreetsByCityId (cityId) {
        return this.$api.getStreetsByCityId(cityId).then((streets) => {
          this.availableStreets = this._.get(streets, 'data.streets', [])
        })
      }
    }
  };
</script>
