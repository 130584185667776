<template>
  <div :class="['card', 'js-item' , !isAvailable ? 'disabled' : '' ]">
    <div v-if="product.product_image" class="img-wrap">
      <span v-if="isNew" class="new">{{ $t('products.new') }}</span>
      <span v-if="hasPromo" class="new">{{ $t('products.promo') }}</span>
      <img :title="product.meta_title" :src="getProductImage(product.id, product.product_image, 576, 380)" class="img-fluid js-image" />
    </div>
    <div v-else-if="isNew" class="img-wrap no-img">
      <span class="new">{{ $t('products.new') }}</span>
    </div>
    <div class="content">
      <p v-if="!isAvailable" class="text-danger">{{ $t('products.temporarily_disabled') }}</p>
      <h4 class="title">{{ product.product_name }}</h4>
      <p v-if="product.public_info" class="description">{{ product.public_info | truncate(90) }}</p>
      <p><strong>{{ product.final_price.price | price }} / {{ product.measurement }}</strong></p>
    </div>
    <div v-if="!disabled" class="text-center pb-2">
      <add-to-cart-modal
        :disabled="!isAvailable"
        :product="product"
        type="button"
        classes="btn btn-verify uppercase border"
      ></add-to-cart-modal>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      product: {
        type: Object,
        default: function()
        {
          return {};
        }
      },
      disabled: {
        type: Boolean,
        default () {
          return false
        }
      }
    },
    computed: {
      isNew () {
        return parseInt(process.env.PRODUCT_IS_NEW) >= this.$moment().diff(this.$moment(this.product.created_at), 'days')
      },
      isAvailable ()
      {
        const productAvailable = !this.product.disable_order
        const availableAssociatedProducts = this.product.associate_products.filter(product => !product.disable_order)
        return (availableAssociatedProducts.length > 0 || productAvailable)
      },
      hasPromo () {
        const productHasPromo = !this._.isNil(this.product.final_price.rule_id)
        const productAssociationsHasPromo = this.product.associate_products.filter(product => !this._.isNil(product.final_price.rule_id)).length > 0
        return (productHasPromo || productAssociationsHasPromo)
      }
    },
    methods: {
      addedToCart (ev, cartResponse) {
        let vue = this
        var cart = this.$('#cart');
        var productImage = $(ev.target).closest('.js-item').find(".js-image");
        if (productImage.length) {
          this.flyImageTo(productImage, cart)
        }
        this.$root.$emit('updateCartObject', cartResponse)
      }
    }
  };
</script>
