<template>
  <div class="manager">
    <PlainCheckbox
      @change="toggleCheckboxes"
    />
    <BtnGroup
      :only-delete="onlyDelete"
      @markAsSeen="markAsSeen"
      @markAsArchived="markAsArchived"
      @markAsDeleted="markAsDeleted"
    />
  </div>
</template>
<script>
export default {
  props: {
    onlyDelete: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  methods: {
    toggleCheckboxes(val) {
      this.$emit('toggleCheckboxes', val)
    },
    markAsSeen(val) {
      this.$emit('markAsSeen', val)
    },
    markAsArchived(val) {
      this.$emit('markAsArchived', val)
    },
    markAsDeleted(val) {
      this.$emit('markAsDeleted', val)
    }
  }
}
</script>
