<template>
  <section id="content-container" class="section-restaurants">
    <b-container>
      <b-row>
        <b-col sm="12">
          <h1 v-if="seo.county && seo.city" class="pageTitle">{{ computedRestaurants.length +' '+ $t('restaurant.pageTitle.all-in-city', {city: seo.city, county: seo.county}) }}</h1>
          <h1 v-else-if="seo.county" class="pageTitle">{{ computedRestaurants.length +' '+ $t('restaurant.pageTitle.all-in-county', {county: seo.county}) }}</h1>
          <h1 v-else class="pageTitle">{{ computedRestaurants.length +' '+ $t('restaurant.pageTitle.all') }}</h1>
        </b-col>
      </b-row>

      <b-row v-if="categories.length">
        <b-col v-for="(category, index) in categories" :key="index" sm="6" md="6" lg="4" xl="3">
          {{ category.name }}
        </b-col>
      </b-row>
      <b-row v-if="computedRestaurants.length" class="animateCards">
        <b-col v-for="(restaurant, index) in computedRestaurants" :key="index" sm="6" md="6" lg="4" xl="3" class="restaurant-card">
          <RestaurantItem :restaurant="restaurant"></RestaurantItem>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
  export default {
    props: {
      restaurantFilters: {
        type: Object,
        default: function () {
          return {
            countyId: undefined,
            cityId: undefined,
            offers_delivery: undefined,
            category_id: undefined
          }
        }
      },
      seo: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    data: function() {
      return {
        restaurants: [],
        categories: [],
        internalRestaurantFilters: {}
      }
    },
    computed: {
      computedRestaurants () {
        let filters = this.internalRestaurantFilters
        let restaurants = this.restaurants.filter((restaurant) => {
            let deliveryCounties = this._.get(restaurant, 'deliveryCities.data', []).map((deliveryCity) => deliveryCity.county_id)
            let deliveryCities = this._.get(restaurant, 'deliveryCities.data', []).map((deliveryCity) => deliveryCity.city_id)

            if(filters.countyId !== undefined && deliveryCounties.indexOf(filters.countyId) < 0) return false
            if(filters.cityId !== undefined && deliveryCities.indexOf(filters.cityId) < 0) return false
            if(filters.offers_delivery !== undefined && filters.offers_delivery === 1 && restaurant.offers_delivery !== true) return false
            if(filters.category_id) {
              let hasFilter = this._.get(restaurant, 'base_categories.data', []).filter((category) => {
                return parseInt(category.id) === parseInt(filters.category_id)
              }).length > 0
              if(!hasFilter) return false
            }
            return true
        })
        return restaurants.sort(function(a, b){
          if(a.enable_order > b.enable_order) { return -1; }
          if(a.enable_order < b.enable_order) { return 1; }
          return 0;
        })
      },
      baseCategories () {
        return []
      }
    },
    watch: {
      restaurantFilters: {
        handler (val) {
          this.internalRestaurantFilters = {
            ...this.internalRestaurantFilters,
            ...val
          }
        },
        deep: true
      }
    },
    mounted () {
      if (!this.$store.getters['restaurantType/all']) {
        this.internalRestaurantFilters.offers_delivery = 1
      } else {
        this.internalRestaurantFilters.offers_delivery = undefined
      }
      this.$root.$on('setRestaurantType', ({ all }) => {
        if (!all) {
          this.internalRestaurantFilters.offers_delivery = 1
        } else {
          this.internalRestaurantFilters.offers_delivery = undefined
        }
        this.getData()
      })
      this.$root.$on('geolocationDone', () => {
        this.getData()
      })
      this.getData()
    },
    methods: {
      async getData () {
        await this.getDeviceCoords().then(position => {
          this.$store.dispatch('location/setGeoLocation', {
            coords: {
              latitude: position?.coords?.latitude,
              longitude: position?.coords?.longitude,
              altitude: position?.coords?.altitude,
              accuracy: position?.coords?.accuracy
            },
            code: 200,
            message: 'OK'
          })
        }).catch(err => {
          console.error(err)
        }).finally((fin) => {
          this.getRestaurants()
        })
      },
      async getRestaurants(coords) {
        let locationCoords = {};
        try {
          locationCoords = {
            lat: this.$store.state.location.coords.latitude ? this.$store.state.location.coords.latitude : null,
            lng: this.$store.state.location.coords.longitude ? this.$store.state.location.coords.longitude : null,
          }
        }catch (e) { }

          let fields = [
              'id',
              'display_name',
              'slug',
              'settings',
              'offers_delivery',
              'offers_pickup',
              'enable_order',
              'delivery_time',
              'payment_type.label',
              'base_category.id',
              'base_category.title',

          ];
          let withs = [
              'paymentTypes',
              'deliveryCities',
              'base_categories'
          ];
          let appends = [
              'ratingsummary',
              'logo_filename',
              'cover_filename',
              'minimum_delivery_amount'
          ];

        await this.$api.getRestaurants(fields, withs, appends, {}, locationCoords).then(result => {
          this.restaurants = this._.get(result, 'data.data')
          this.emitCategories()
        }).catch((err) => {
          console.error(err);
        })
      },
      emitCategories () {
        var categories = []
        this.restaurants.map((restaurant) => {
          categories = this._.concat(categories, this._.get(restaurant, 'base_categories.data', []))
        })
        categories = this._.uniqBy(categories, function (category) {
            return category.id;
        });
        this.$emit('categoryChange', categories);
      }
    }
  };
</script>
