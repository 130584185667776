<template>
  <section v-if="isLoaded" class="card max-424 overflow-y-auto on-backdrop loginform">
    <b-btn variant="outline" class="closer icon-close" @click="back"></b-btn>
    <h2 class="title">
      {{ $t('loginForm.title') }}
      Yummy.ro
    </h2>
    <form>
      <div class="form-group">
        <input v-model="user.username" :placeholder="($t('registerForm.input.username'))" name="username" class="form-control" />
      </div>
      <div :class="['form-group pass-swithcer', toggleVisibility ? 'switched' : '']">
        <input v-model="user.password" :placeholder="($t('registerForm.input.password'))" type="password" name="password" class="form-control ipassword" />
        <input v-model="user.password" :placeholder="($t('registerForm.input.password'))" type="text" name="password" class="form-control itext" />
        <b-btn class="swither" @click="toggleVisibility = !toggleVisibility"><i class="icon-visibility"></i></b-btn>
      </div>
      <div class="form-group">
        {{ $t('loginForm.forgotPass') }}&nbsp;&nbsp;
        <nuxt-link v-if="!isEmbed()" :to="{ name: 'password-reset' }">{{ $t('loginForm.passRecoverLink') }}</nuxt-link>
        <a v-else :href="resolveUrl({name: 'password-reset'})" target="_blank">{{ $t('loginForm.passRecoverLink') }}</a>
      </div>
      <div class="form-group">
        <p v-if="error" class="text-danger">{{ error }}</p>
        <b-btn :class="['login fullwidth uppercase', error ? 'disabled' : '']" variant="primary" type="submit" @click.prevent="login">
          {{ $t('loginForm.loginText') }}
          <i class="icon-ok" />
        </b-btn>
      </div>
      <div class="form-group text-center py-2">
        <span class="lines">{{ $t('loginForm.alternativeLogin') }}</span>
      </div>
      <b-row>
        <b-col xs="12" sm="6" class="form-group pr-sm-2">
          <b-btn class="fullwidth btn-fb" type="submit" @click.prevent="loginWithFacebook">
            <i class="icon-facebook" />
            Facebook
          </b-btn>
        </b-col>
        <b-col xs="12" sm="6" class="form-group pl-sm-2">
          <b-btn class="fullwidth btn-ggl" type="submit" @click.prevent="loginWithGoogle">
            <i class="icon-google" />
            Google
          </b-btn>
        </b-col>
      </b-row>
      <div class="form-group mt-3">
        <strong v-if="!isEmbed()">
          {{ $t('loginForm.noAccount') }}&nbsp;Yummy?&nbsp;&nbsp;<nuxt-link :to="{ name: 'register' }">{{ $t('loginForm.createAccount') }}</nuxt-link>
        </strong>
        <strong v-else>
          {{ $t('loginForm.noAccount') }}&nbsp;Yummy?&nbsp;&nbsp;<a :href="resolveUrl({name: 'register'})" target="_blank">{{ $t('loginForm.createAccount') }}</a>
        </strong>
      </div>
    </form>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        isLoaded: false,
        toggleVisibility: false,
        error: undefined,
        user: {
          username: '',
          password: ''
        }
      }
    },
    async mounted () {
      let token = this._.get(this.$route.params, 'token')
      let email = this._.get(this.$route.params, 'email')
      if(token) {
        await this.$api.activateAccount(token, email)
        .then((res) => {
          this.showSuccess(this._.get(res, 'data.messages.0'))
          this.$router.replace({ name: 'login' })
        }).catch((err) => {
          this.showError(Object.values(this._.get(err, 'response.data.errors', {})))
        }).finally(() => {
          this.isLoaded = true
        })
      } else {
        this.isLoaded = true
      }
    },
    methods: {
      async login() {
        let that = this;
        await this.$api.login(this.user.username, this.user.password).then(response => {
          if(this.isEmbed()) {
            this.$router.push({name: 'embed-cart'})
          } else {
            that.$router.replace('/')
          }
        }).catch(async (err) => {
          await this.$auth.strategy._logoutLocally()
          if(err.response.status === 403) {
            that.error = err.response?.data?.message
          } else {
            console.error(err)
          }
        })
      },
      async loginWithFacebook () {
        this.$store.dispatch('embed/setRedirectAfterSocialLogin', {status: true})
        this.$auth.loginWith('laravel_facebook');
      },
      async loginWithGoogle () {
        this.$store.dispatch('embed/setRedirectAfterSocialLogin', {status: true})
        this.$auth.loginWith('laravel_google');
      },
      resolveUrl(url) {
        let { href } = this.$router.resolve(url)
        return href
      }
    }
  }
</script>
