<template>
  <div class="order">
    <div v-if="order.status" :class="['ordertype', order.status === 'ACCEPTED' ? 'accepted' : null , order.status === 'NEW' ? 'new' : null, order.status === 'DELIVERED' ? 'delivered' : null, order.status === 'CANCELED' ? 'canceled' : null ]">
      <span>{{ getOrderStatus(order.status, 'text') }}</span>
    </div>
    <hr v-if="order.status">

    <div class="d-flex justify-content-between">
      <div><strong>{{ $t('order.client_display_name') }}</strong></div>
      <div><strong>{{ order.client_display_name }}</strong></div>
    </div>
    <hr v-if="order.client_display_name">

    <div class="d-flex justify-content-between">
      <div><strong>{{ $t('order.address_name') }}{{ order.address_name }}</strong></div>
      <div><strong>{{ order.phone ? $t('registerForm.input.phone') : null }} : {{ order.phone }}</strong></div>
    </div>
    <hr v-if="order.address_name || order.phone">

    <div>
      <span v-if="order.county_name || order.city_name || order.street_name || order.street_no > 0 || order.street_ap > 0">
        <strong>{{ $t('addresses.address') }} :</strong>
      </span>
      {{ order.county_name ? order.county_name : null }}
      {{ order.city_name ? ', ' : null }} {{ order.city_name ? order.city_name : null }}
      {{ order.street_name ? ', ' : null }} {{ order.street_name ? order.street_name : null }}
      {{ order.street_no > 0 ? ', ' : null }} {{ order.street_no > 0 ? order.street_no : null }}
      {{ order.street_ap > 0 ? ', ' : null }} {{ order.street_ap > 0 ? order.street_ap : null }}
    </div>
    <hr v-if="order.county_name || order.city_name || order.street_name || order.street_no > 0 || order.street_ap > 0">

    <div v-if="order.order_reception" class="d-flex justify-content-between">
      <div>
        <strong>{{ $t('order.created_at') }} :</strong>
        {{ formatDate( order.created_at, 'DD/MM/YYYY, HH:mm') }}
      </div>
    </div>
    <div v-if="order.order_delivered">
      <div>
        <strong>{{ $t('order.order_delivered') }} :</strong>
        {{ formatDate( order.order_delivered, 'DD/MM/YYYY, HH:mm') }}
      </div>
    </div>
    <div v-if="order.status === 'CANCELED' && order.order_canceled">
      <div>
        <strong>{{ $t('order.order_canceled') }} :</strong>
        {{ formatDate( order.order_canceled, 'DD/MM/YYYY, HH:mm') }}
        <!-- eslint-disable vue/no-v-html -->
        <p v-if="order.cancel_message" v-html="order.cancel_message"></p>
      </div>
    </div>

    <br>
    Produse:
    <ul>
      <template v-for="(product, pIndex) in orderProducts">
        <li :key="`product${pIndex}`" class="d-flex">
          {{ _.get(product, 'quantity', 0) | number }} x
          {{ _.get(product, 'product.data.product_name') }}
          <span class="ml-auto">
            {{ _.get(product, 'amount_with_vat', 0) | price }}
          </span>
        </li>
        <li v-if="!_.isNil(product.cart_rule_details)" :key="`rule${pIndex}`" class="d-flex">
          <b-badge variant="success">
            <template v-if="product.cart_rule_details.discount_type === 'NONE'">
              {{ $t('order.cart_rule_free_product') }}
            </template>
            <template v-else-if="product.cart_rule_details.discount_type === 'PERCENT'">
              {{ $t('order.cart_rule_percent', { value: $options.filters.number(product.cart_rule_details.discount_value, { style: 'decimal', minimumFractionDigits: 0}) }) }}
            </template>
            <template v-else-if="product.cart_rule_details.discount_type === 'VALUE'">
              {{ $t('order.cart_rule_value', { value: $options.filters.price(product.cart_rule_details.discount_value, { style: 'currency', currency: 'RON', minimumFractionDigits: 0}) }) }}
            </template>
          </b-badge>
        </li>
      </template>
    </ul>

    <div v-if="order.remarks" class="remarks">
      {{ $t('remarks') }} : {{ order.remarks }}
    </div>

    <hr>
    <div v-if="order.amount > 0" class="d-flex justify-content-between">
      <div><strong>{{ $t('totalAmount') }} :</strong></div><div><strong>{{ order.amount | price }}</strong></div>
    </div>

    <ul class="pl-0 mt-1">
      <li v-for="( payment, pIndex ) in _.get( order, 'payments.data',[] )" :key="pIndex" class="d-flex justify-content-between">
        <div>{{ _.get(payment, 'payment.data.label') }}</div>
        <div>{{ payment.amount | price }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      order: {
        type: Object,
        default: function()
        {
          return {};
        }
      }
    },
    computed: {
      orderProducts () {
        return this._.get(this.order, 'products.data', []).sort(function(a, b){
            if (a.is_free_product === null) {
                return -1;
            } else if (b.is_free_product === null) {
                return 1;
            }
            return 0;
        })
      }
    }
  };
</script>
