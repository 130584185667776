<template>
  <div v-if="restaurant.id" class="container restaurant-detailpage">
    <div v-if="showHeader" class="row btn-line mt-3 mb-3">
      <div v-if="!isEmbed()" class="col-12 col-lg-3 d-flex mb-2 mb-lg-0">
        <nuxt-link :to="{name: 'restaurants'}" class="btn btn-warning">
          <i class="icon-arrow-left" />{{ $t('backto') }}
        </nuxt-link>
      </div>
      <div class="col-12 col-lg-6 d-flex">
        <h1 class="pageTitle d-flex justify-content-center align-self-center my-2 my-lg-0">{{ restaurant.display_name }}</h1>
      </div>
    </div>
    <div v-if="!isAvailable" class="row btn-line mt-3 mb-3">
      <div class="col-12 col-lg-12 d-flex justify-content-center">
        <p class="text-danger font-weight-bolder">{{ $t('restaurantDetail.temporarily_disabled') }}</p>
      </div>
    </div>

    <div v-if="showInfo" class="row mx-0 mb-3">
      <div class="with-papers">
        <div class="col-12 card px-0">
          <div class="row mx-0 d-block d-md-flex flex-grow-md-1">
            <!-- Slider -->
            <!-- Slick no jQuery : https://github.com/staskjs/vue-slick -->
            <div v-if="promotedProducts.length || !_.isNil(restaurant.cover_filename)" class="col-12 col-md-6 px-0 sliderHolder order-1 order-md-2">
              <img v-if="promotedProducts.length" src="/img/recomandate.png" class="recomandate" />
              <template v-if="promotedProducts.length">
                <vue-slick id="promoSlider" ref="promoSlider" :options="promoSliderOptions" @init="handleInit" @afterChange="handleAfterChange">
                  <div v-for="(promoProduct, index) in promotedProducts" :key="index" class="js-item">
                    <div :style="{ 'backgroundImage': 'url(' + getProductImage(promoProduct.id, promoProduct.product_image, 768, 768) + ')'}" :class="['bg-image', 'js-image', promoProduct.product_name ? 'with-content':null]" />
                    <div class="slide-content">
                      <div class="content">
                        <h4 v-if="promoProduct.product_name" class="title">{{ promoProduct.product_name }}</h4>
                        <p v-if="promoProduct.public_info" class="description">{{ promoProduct.public_info | truncate(90) }}</p>
                      </div>
                      <div class="price-btnwrap">
                        <p v-if="promoProduct.price_with_vat" class="price">{{ promoProduct.price_with_vat | price }} / {{ promoProduct.measurement }}</p>
                        <div v-if="isAvailable" class="text-center pb-2">
                          <add-to-cart-modal
                            :disabled="!isAvailable"
                            :product="promoProduct"
                            type="button"
                            classes="btn"
                          ></add-to-cart-modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </vue-slick>
                <div :class="['nav-wrap', totalslide > 1 ? 'd-flex' : 'd-none']">
                  <div class="nav-wrap-inner d-flex">
                    <div class="counter order-2"><span>{{ currentSlide }}</span><span>&nbsp;/&nbsp;</span><span>{{ totalslide }}</span></div>
                  </div>
                </div>
              </template>
              <template v-else>
                <img :src="getImage('images', restaurant.cover_filename, 768, 360)" :alt="restaurant.display_name" style="transform:scale(1.3);" />
              </template>
            </div>
            <!-- /Slider -->

            <div :class="['program', promotedProducts.length || !_.isNil(restaurant.cover_filename) ? 'col-12 col-md-6 pr-md-0 order-2 order-md-1' : 'col-12 order-1']">
              <div v-if="restaurant.ratingsummary.avg" class="rating-line">
                <div
                  :style="rating"
                  :class="['rating-sprite rating-'+restaurant.ratingsummary.avg.toFixed(0) ]"
                ></div>
                <div v-if="restaurant.logo_filename" class="imgwrap">
                  <img :src="getImage('images', restaurant.logo_filename, 150, 150)" :alt="restaurant.display_name" />
                </div>
                <div v-else class="imgwrap">
                  <img src="/img/norestaurant.png" alt="yummy.ro" />
                </div>
                <div class="avgblock">
                  <strong class="avg">{{ restaurant.ratingsummary.avg.toFixed(1) }}</strong>
                  ({{ restaurant.ratingsummary.count }})
                </div>
              </div>
              <div v-else class="rating-line">
                <div :style="rating" class="rating-0 rating-sprite"></div>
                <div class="avgblock">
                  {{ restaurant.ratingsummary.count === 0 ? '(0)' : restaurant.ratingsummary.count }}
                </div>
              </div>
              <div v-if="restaurant.delivery_time" class="fragment livrare mb-2">
                <i class="icon-timer" />
                <strong>Timp mediu de livrare:</strong> {{ restaurant.delivery_time }} {{ $t('restaurant.min') }}
              </div>
              <div v-if="workhours instanceof Object && workhours.week.length" class="fragment orar mb-2">
                <i class="icon-date" />
                <strong>{{ $t('restaurantDetail.workingHours.title') }}:</strong>
                <div v-if="workhours.today" v-b-toggle="'workhours-toggle'" class="lbl ml-5">
                  <strong>{{ workhours.today.label }}:</strong>
                  <span class="date">{{ workhours.today.start }} - {{ workhours.today.end }}</span>
                </div>
                <b-collapse id="workhours-toggle">
                  <div v-for="(workhour, wIndex) in workhours.week" :key="wIndex" class="lbl">
                    <strong>{{ workhour.label }}:</strong>
                    <span class="date">{{ workhour.start }} - {{ workhour.end }}</span>
                  </div>
                </b-collapse>
              </div>
              <div v-if="_.get(restaurant,'paymentTypes.data',[]).length" class="fragment plata mb-2">
                <i class="icon-creditcard" />
                <strong>{{ $t('cart.paymentTypes') }}:</strong>
                {{ _.get(restaurant,'paymentTypes.data',[]).map(type => type.label ).join(', ') }}
              </div>
              <div class="fragment delivery mb-2">
                <i class="icon-delivery" />
                <strong>{{ $t('cart.serviceType') }}:</strong>
                {{ restaurant.offers_delivery ? "Cu livrare" : null }}
                {{ restaurant.offers_pickup ? "ridicare": null }}
              </div>
              <div v-if="deliveryCities" class="fragment locatii mb-5">
                <i class="icon-room" />
                <strong>{{ $t('restaurantDetail.deliverLocations') }}:</strong>
                <div v-if="deliveryCities.activeCity instanceof Object" v-b-toggle="'workhours-toggle2'" class="lbl-loc ml-5">
                  <strong>{{ deliveryCities.activeCity.name }}</strong>
                  <span class="loc"> Livrare minima: {{ _.get(deliveryCities.activeCity, 'min_amount', 0) | price }}</span>
                </div>
                <b-collapse id="workhours-toggle2">
                  <div v-for="(city, index) in _.get(deliveryCities, 'otherCities', [])" :key="index" class="lbl-loc">
                    <strong>{{ city.name }}</strong>
                    <span class="loc"> Livrare minima: {{ _.get(city, 'min_amount', 0) | price }}</span>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <span class="paper p1" />
        <span class="paper p2" />
      </div>
    </div>

    <!--
    <div class="row mx-0">
      <div class="col-12 card mb-3">{{ $t('restaurantDetail.meniul_zilei.title') }} / on hold</div>
    </div>
    -->

    <div v-if="showProducts && products.length" id="content-container">
      <div class="hr-line">
        <h3>{{ productsContainerTitle }}</h3>
      </div>
      <div v-masonry="products" transition-duration="0.3s" item-selector=".product-item" stagger="0.03s" class="row d-block masonry-container">
        <div v-for="(product, index) in products" :key="index" class="col-sm-6 col-md-6 col-lg-4 col-xl-3 product-item d-block">
          <product-item :product="product" :disabled="!isAvailable" />
        </div>
      </div>
    </div>

    <div v-if="showReviews" id="reviews" class="row mx-0">
      <div class="col-12 card review-component mb-5">
        <b-row>
          <div :class="['reviews order-2 order-md-1', opened ? 'opened' : '']">
            <button type="button" name="expander" class="btn icon-arrow-up" @click="opened = !opened"></button>
            <h5 class="title">{{ $t('restaurantDetail.reviews') }}</h5>
            <div class="reviews-wrapper">
              <div v-for="(userReview, rIndex) in restaurantReviews" :key="rIndex" class="reviews-wrap">
                <user-review :review="userReview"></user-review>
              </div>
              <div v-if="_.get(restaurantReviewsMeta, 'pagination.current_page', 0) < _.get(restaurantReviewsMeta, 'pagination.total_pages', 0)" class="reviews-wrap justify-content-center">
                <b-btn class="load-more" @click="loadReviews">{{ $t('restaurantDetail.review.loadMore') }}</b-btn>
              </div>
              <div v-else class="reviews-wrap justify-content-center">
                <button v-if="$auth.loggedIn" type="button" class="btn btn-warning" @click="toggleReviewPopup">{{ $t('restaurantDetail.addReview') }}</button>
                <div v-else class="unlogged">
                  {{ $t('restaurantDetail.addReviewLogin') }}
                  <nuxt-link :to="{name:'login'}" class="btn btn-primary mx-2">{{ $t('restaurantDetail.addReviewLoginText') }}</nuxt-link>
                </div>
              </div>
            </div>
          </div>
          <div class="ratingsummary order-1 order-md-2">
            <div v-if="restaurant.ratingsummary.avg" class="rating-line main-rating">
              <div :style="rating" :class="['rating-sprite rating-'+restaurant.ratingsummary.avg.toFixed(0) ]">
              </div>
              <div class="avgblock">
                {{ $t('restaurantDetail.reviews_average', { avg:restaurant.ratingsummary.avg.toFixed(1), count: restaurant.ratingsummary.count }) }}
              </div>
            </div>
            <div v-if="_.has(restaurant, 'ratingsummary.breakdown', [])" class="all-ratings">
              <div v-for="(breakdownStepCount, bStep) in _.get(restaurant, 'ratingsummary.breakdown', [])" :key="bStep" class="rating-line">
                <div :style="rating" :class="['rating-sprite rating-' + String(bStep).replace('_star', '') ]"></div>
                <div class="text">{{ $t(`restaurantDetail.reviews_breakdown.${bStep}`) }} {{ breakdownStepCount }}</div>
              </div>
            </div>
            <button v-if="$auth.loggedIn" type="button" class="btn btn-warning" @click="toggleReviewPopup">{{ $t('restaurantDetail.addReview') }}</button>
            <div v-else class="unlogged">
              {{ $t('restaurantDetail.addReviewLogin') }}
              <nuxt-link :to="{name:'login'}" class="btn btn-primary">{{ $t('restaurantDetail.addReviewLoginText') }}</nuxt-link>
            </div>

            <popup v-if="review.popupOpened">
              <template v-slot:header>
                <div class="header-inner d-flex justify-content-between">
                  <h3 class="modal-title">{{ $t('restaurantDetail.addReview') }}</h3>
                  <button type="button" class="btn-close icon-close" @click="review.popupOpened = false">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </template>
              <template v-slot:body>
                <div class="d-flex justify-content-sm-between align-items-center my-2">
                  <label :class="['d-flex ratings', review.rating ? 'rating-'+review.rating : 'rating-0']">
                    <span class="text">{{ $t('restaurantDetail.review.rating') }}</span>
                    <div class="rating">
                      <input v-model="review.rating" type="radio" name="rating" value="1">
                      <span class="ico icon-star"></span>
                    </div>
                    <div class="rating">
                      <input v-model="review.rating" type="radio" name="rating" value="2">
                      <span class="ico icon-star"></span>
                    </div>
                    <div class="rating">
                      <input v-model="review.rating" type="radio" name="rating" value="3">
                      <span class="ico icon-star"></span>
                    </div>
                    <div class="rating">
                      <input v-model="review.rating" type="radio" name="rating" value="4">
                      <span class="ico icon-star"></span>
                    </div>
                    <div class="rating">
                      <input v-model="review.rating" type="radio" name="rating" value="5">
                      <span class="ico icon-star"></span>
                    </div>
                  </label>
                  <div v-if="review.rating" class="d-none d-sm-flex">
                    {{ $t('restaurantDetail.review.yourRating') }}
                    <strong>&nbsp; {{ review.rating }} &nbsp;</strong>
                    {{ $t('restaurantDetail.review.yourRatingFrom5') }}
                  </div>
                </div>
                <label class="textarea">
                  <span class="text">{{ $t('restaurantDetail.review.review') }}</span>
                  <textarea v-model="review.review" class="form-control"></textarea>
                </label>
                <label class="checkbox">
                  <input v-model="review.anonym" type="checkbox" />
                  <span class="ico"></span>
                  <span class="text">{{ $t('restaurantDetail.review.anonym') }}</span>
                </label>
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="$t('restaurantDetail.review.terms_and_conditions')"></p>
              </template>
              <template v-slot:footer>
                <p v-if="!$auth.loggedIn">{{ $t('restaurantDetail.review.please_log_in') }}</p>
                <b-btn v-else class="yellow" @click="submitReview">{{ $t('restaurantDetail.review.submit') }}</b-btn>
              </template>
            </popup>
          </div>
        </b-row>
      </div>
    </div>

    <div v-if="phoneNumbers.length" class="phone fixed">
      <div class="animated-phone" />
      <div class="animated-phone" />
      <input type="checkbox">
      <i class="icon-phone" />
      <div class="mobile-shadow" />
      <ul>
        <li v-for="(phone, index) in phoneNumbers" :key="index">
          <a :href="'tel:' + phone">
            <i class="icon-phone" /> {{ phone }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import WorkhoursHelper from '~/plugins/mixins/workhours'
import DeliveryCitiesHelper from '~/plugins/mixins/delivery-cities'
import RestaurantSeoHelper from '~/utils/restaurant-seo-helper'
export default {
  mixins: [
    RestaurantSeoHelper,
    WorkhoursHelper,
    DeliveryCitiesHelper
  ],
  props: {
    restaurantSlug: {
      type: String,
      default: function() {
        return undefined
      }
    },
    productFilters: {
      type: Object,
      default: function () {
        return {
          category_id: undefined
        }
      }
    },
    showHeader: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    showInfo: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    showProducts: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    showReviews: {
      type: Boolean,
      default: function () {
        return true
      }
    }
  },
  data: function() {
    return {
      opened:false,
      page: undefined,
      totalslide: undefined,
      currentSlide: 1,
      isUnder768: typeof window !== 'undefined' ? window.innerWidth < 768 : false,
      promoSliderOptions: {
        slidesToShow: 1,
        autoplay:true,
        arrows:true,
        autoplaySpeed: 8000,
        appendArrows:".nav-wrap-inner",
        prevArrow:'<button type="button" class="slick-prev icon-arrow-left order-1"></button>',
        nextArrow:'<button type="button" class="slick-next icon-arrow-right order-3"></button>',
        responsive:true
      },
      restaurant: {},
      restaurantProducts: [],
      restaurantReviews: [],
      restaurantReviewsMeta: {
        pagination: {
          current_page: 0
        }
      },
      deliveryCities: undefined,
      products: [],
      rating: { backgroundImage: 'url(/img/rating.png)' },
      review: {
        popupOpened: false,
        rating: undefined,
        review: undefined,
        anonym: false
      }
    }
  },
  computed: {
    isAvailable () {
      return this._.get(this.restaurant, 'enable_order', false)
    },
    workhours () {
      if(this.restaurant?.settings && this.restaurant.settings instanceof Object) {
        return this.assemblyWorkhoursList(this.restaurant.settings)
      }
      return []
    },
    promotedProducts() {
      return this.restaurantProducts.filter(
        product =>
          !this._.isNil(product.product_tags) &&
          product.product_tags.includes('recomandat') &&
          !this._.isNil(product.product_image) &&
          product.disable_order !== 1
      )
    },
    phoneNumbers() {
      if (this._.isNil(this.restaurant.phone_numbers)) return []
      return this.restaurant.phone_numbers.split(/\n/)
    },
    productsContainerTitle () {
      if (this.productFilters.category_id) {
        var selectedCategory = this.flatCategories.find((category) => category.id === this.productFilters.category_id)
        return this.$t('products.product_with_category', {count: this.products.length, category: selectedCategory?.title })
      } else {
        return this.$t('products.product', {count: this.products.length})
      }
    },
    flatCategories () {
      var categories = []
      this.restaurant.categories.map((category) => {
        categories.push(category)
        this._.get(category, 'children', []).map((child) => {
          categories.push(child)
        })
      })
      return categories
    }
  },
  watch: {
    'restaurant.categories': {
      handler (val) {
        this.$emit(
          'categoryChange',
          val
        )
      }
    },
    productFilters: {
      handler (val) {
        this.computeProducts()
      },
      deep: true
    }
  },
  async mounted() {
    let vue = this
    if (undefined === this.restaurantSlug) {
      return this.$router.replace('/404')
    }
    await this.getPageHead().then((res) => {
      this.page = res
    })

    let withs = [
      'categories',
      'products',
      'paymentTypes',
      'deliveryCities',
      'images'
    ]
    await this.$api
      .getRestaurant(this.restaurantSlug, undefined, withs)
      .then(result => {
        this.restaurant = this._.get(result, 'data.data')
        this.restaurant.categories = this._.get(this.restaurant, 'categories.data', []).map((category) => {
            category.children = this._.get(category, 'children.data', []).sort(function(a, b){
                if(a.order < b.order) { return -1; }
                if(a.order > b.order) { return 1; }
                return 0;
            })
            return category
        }).sort(function(a, b){
            if(a.order < b.order) { return -1; }
            if(a.order > b.order) { return 1; }
            return 0;
        })
      }).then(() => {
          return this.$api.getRestaurantProducts(this.restaurant.id).then((products) => {
            this.restaurantProducts = this._.get(products, 'data.data', []).map((product) => {
              product.associate_products = this._.get(product, 'associate_products.data', [])
              product.categories = this._.get(product, 'categories.data', []).map((category) => {
                return category.id
              })
              return product
            }).sort(function(a, b){
                var aItems = [a, ...a.associate_products]
                var bItems = [b, ...b.associate_products]
                var aPromotions = aItems.filter(product => !vue._.isNil(product.final_price.rule_id)).length
                var bPromotions = bItems.filter(product => !vue._.isNil(product.final_price.rule_id)).length
                var aDisabled = aItems.filter(product => product.disable_order).length
                var bDisabled = bItems.filter(product => product.disable_order).length
                if (aPromotions < bPromotions) {
                    return 1;
                } else if (aPromotions > bPromotions) {
                    return -1;
                } else if (aDisabled < bDisabled) {
                    return -1;
                } else if (aDisabled > bDisabled) {
                    return 1;
                } else if (a.product_name < b.product_name) {
                    return -1;
                } else if (a.product_name > b.product_name) {
                    return 1;
                }
                return 0;
            })
          })
      }).then(() => {
        this.computeProducts()
      }).then(() => {
        this.computeDeliveryCities()
      })
      .catch(err => {
        if (err.response.status) {
            return this.$router.replace('/404')
        } else console.error(err)
      })
    await this.loadReviews()
    setTimeout(() => {
        let hash = this.$route.hash;
        if(typeof window !== 'undefined' && hash)
        {
            var elem = document.querySelector(hash)
            if(elem) {
                return window.scrollTo({ top: document.querySelector(hash).offsetTop - 100, behavior: 'smooth' });
            }
        }
    }, 1000)
  },
  methods: {
    async loadReviews () {
      let nextPage = this._.get(this.restaurantReviewsMeta, 'pagination.current_page', 0) + 1
      await this.$api.getReviews(this.restaurant.id, nextPage, 15, ['-created_at']).then((reviews) => {
        this.restaurantReviewsMeta = this._.get(reviews, 'data.meta', {})
        this._.get(reviews, 'data.data', []).map((review) => {
          review.replies = this._.get(review, 'replies.data', [])
          this.restaurantReviews.push(review)
        })
      })
      .catch(err => {
        console.error(err)
      })
    },
    submitReview () {
      let data = {
        related_to: null,
        rating: this.review.rating,
        review: this.review.review,
        anonym: this.review.anonym ? 1 : 0,
      }
      this.$api.addReview(this.restaurant.id, data).then((res) => {
        this.showSuccess(this.$t('restaurantDetail.review.added-successfully'))
        this.review.rating = undefined
        this.review.review = undefined
        this.review.anonym = false
        this.review.popupOpened = false
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showError(err.response)
        } else {
          this.showError(this.$t('general.messages.error'))
        }
      })
    },
    toggleReviewPopup () {
      this.review.popupOpened = !this.review.popupOpened
    },
    handleInit(event,slick) {
      this.totalslide = slick.slideCount;
    },
    handleAfterChange(event, slick, currentSlide) {
      this.currentSlide = currentSlide + 1;
    },
    addedToCart (ev, cartResponse) {
      var cart = this.$('#cart');
      var productImage = $(ev.target).closest('.js-item').find(".js-image");
      if (productImage.length) {
        this.flyImageTo(productImage, cart)
      }
      this.$root.$emit('updateCartObject', cartResponse)
    },
    computeProducts () {
      let that = this
      this.products = []
      this.$nextTick(() => {
        let products = [
            ...this.restaurantProducts
        ]
        if (that.productFilters.category_id) {
            products = products.filter((product) => {
                return product.categories.indexOf(that.productFilters.category_id) >= 0
            })
        }
        that.products = products
      })
    },
    async computeDeliveryCities () {
      this.deliveryCities = undefined
      let deliveryCities = this._.get(this.restaurant, 'deliveryCities.data', [])
      if(deliveryCities.length) {
        await this.assemblyDeliveryCitiesList(deliveryCities).then((computed) => this.deliveryCities = computed)
      }
    }
  },
  head() {
    return this.buildHead(this.page, this.restaurant)
  }
}
</script>
