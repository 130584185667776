<template>
  <b-row>
    <b-col class="my-account">
      <div class="account-card helper">
        <h3 class="block-title text-center">{{ $t('header.welcome') }} {{ $auth.user.client_name || $auth.user.name || '' }}</h3>
        <h5 class="block-subtitle">{{ $t('myAccount.addresses.subTitle') }}</h5>
      </div>

      <addresses />

      <div class="account-card helper">
        <a href="" class="link"><i class="icon-help"></i>{{ $t('myAccount.addresses.helpLink') }}</a>
      </div>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    data: function () {
      return {
        page: undefined
      }
    },
    mounted() {
      this.getPageHead().then((res) => {
        this.page = res
      })
    },
    head() {
      var meta = [
        { hid: 'og:title', property: 'og:title', content: this.page?.title },
        { hid: 'og:type', property: 'og:type', content: 'website'}
      ];
      if(this.page?.meta_description) {
        meta.push({ hid: 'description', name: 'description', content: this.page?.meta_description })
        meta.push({ hid: 'og:description', property: 'og:description', content: this.page?.meta_description })
      }
      if(this.page?.og_image) {
        meta.push({ hid: 'og:image', property: 'og:image', content: this.page?.og_image })
      }
      return {
        title: this.page?.title,
        meta: meta
      }
    }
  };
</script>
