<template>
  <div :class="['notification-item', opened ? 'opened' :'']">
    <div v-if="_.isNil(notification.read_at)" class="new">{{ $t('bullets.newItem') }}</div>
    <h3 class="title" :data-date="new Date(notification.created_at) | date" @click="opened = !opened">{{ notification.title }}</h3>
    <!-- eslint-disable vue/no-v-html -->
    <div class="paragraph-wrapper">
      <img v-if="notification.image" :src="getImage('images', notification.image, 200, 200)" class="img" />
      <p class="paragraph" v-html="notification.message"></p>
    </div>
    <div class="row-line">
      <PlainCheckbox :checked-prop="notification.checked" @change="checkboxChange" />
      <BtnGroup
        :show-seen="_.isNil(notification.read_at)"
        :show-archived="_.isNil(notification.archived_at)"
        @markAsSeen="markAsSeen"
        @markAsArchived="markAsArchived"
        @markAsDeleted="markAsDeleted"
      />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      notification: {
        type: Object,
        default: function()
        {
          return {
            checked: false
          };
        }
      },
      markArchived: {
        type: Function,
        default: function() {
          return function() {}
        }
      },
      markSeen: {
          type: Function,
          default: function() {
              return function() {}
          }
      },
      markDeleted: {
          type: Function,
          default: function() {
              return function() {}
          }
      }
    },
    data:function(){
      return {
        opened:false
      }
    },
    methods: {
      checkboxChange (val) {
        this.$emit('checkboxChange', val)
      },
      markAsSeen() {
        this.markSeen(this.notification.id).then((res) => {
          this.showSuccess(this.$t('general.messages.success'))
        }).catch((err) => {
          this.showError(this.$t('general.messages.error'))
        }).finally(() => {
          this.triggerReload()
        })
      },
      markAsArchived() {
        this.markArchived(this.notification.id).then((res) => {
          this.showSuccess(this.$t('general.messages.success'))
        }).catch((err) => {
          this.showError(this.$t('general.messages.error'))
        }).finally(() => {
          this.triggerReload()
        })
      },
      markAsDeleted() {
        this.markDeleted(this.notification.id).then((res) => {
          this.showSuccess(this.$t('general.messages.success'))
        }).catch((err) => {
          this.showError(this.$t('general.messages.error'))
        }).finally(() => {
          this.triggerReload()
        })
      },
      triggerReload () {
        this.$emit('triggerReload')
      }
    }
  };
</script>
