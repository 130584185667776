
<template>
  <div class="col-12">
    <div class="row">
      <div :class="['text-center voucher-bg d-flex', voucher.percent !== 100 ? 'generating-bg' : null ]">{{ voucher.value }} ron</div>
      <div v-if="voucher.percent !== 100" class="code-bg generating">
        <div>
          <span class="icon-voucher" />
          <span class="">{{ voucher.percent }}% {{ $t('myAccount.vouchers.generatingVoucher') }}</span>
        </div>
        <div class="bar-bg">
          <div class="bar" :style="{width:[ voucher.percent+'%' ]}" />
        </div>
      </div>
      <div v-else class="code-bg">
        <div class="v-code d-flex">
          <span v-if="!voucher.used_at" class="icon-voucher" />
          <span :class="['copy-code d-flex', voucher.used_at ? 'flex-column' : null]">
            <span class="cod">{{ voucher.used_at ? "Utilizat" : $t('myAccount.vouchers.voucherCode') + " :" }}</span>
            <button v-if="voucher.percent === 100" v-clipboard="copyData" type="button" name="copyToClipboard" class="flex-grow-1 d-flex copy-code-clipboard icon-filter-none" @success="handleSuccess" @error="handleError">
              {{ voucher.used_at ? formatDate( voucher.used_at, 'DD/MM/YYYY' ) + " - " + formatDate( voucher.used_at, 'HH:mm' ) : null }}
              {{ voucher.used_at ? " , " : voucher.code }}
              {{ voucher.used_at ? voucher.used_at_restaurant : voucher.code }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      voucher: {
        type: Object,
        default: function()
        {
          return {};
        }
      }
    },
    data() {
      return {
        copyData: this.voucher.code
      }
    },
    methods: {
      handleSuccess(e) {
        this.showSuccess( [ this.voucher.code + ' a fost copiat cu succes']);
      },
      handleError(e) {
        console.warn(e);
      }
    }
  };
</script>
