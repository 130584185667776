<template>
  <b-row>
    <b-col class="my-account vouchers">
      <div class="account-card">
        <h3 class="block-title text-center">{{ $t('header.welcome') }} {{ $auth.user.client_name || $auth.user.name || '' }}</h3>
        <h5 class="block-subtitle">{{ $t('myAccount.vouchers.subTitle') }}</h5>
        <b-tabs content-class="">
          <b-tab v-if="activeVouchers.length" active>
            <template v-slot:title>
              {{ $t('myAccount.vouchers.activeVouchers') }} <span>{{ activeVouchers.length }}</span>
            </template>
            <ul class="tab-list voucher voucher-active">
              <li v-for="(voucher, index) in activeVouchers" :key="index">
                <Voucher :voucher="voucher"></Voucher>
              </li>
            </ul>
          </b-tab>
          <b-tab v-if="usedVouchers.length">
            <template v-slot:title>
              {{ $t('myAccount.vouchers.usedVouchers') }} <span>{{ usedVouchers.length }}</span>
            </template>
            <ul class="tab-list voucher voucher-used">
              <li v-for="(voucher, index) in usedVouchers" :key="index">
                <Voucher :voucher="voucher"></Voucher>
              </li>
            </ul>
          </b-tab>
        </b-tabs>
        <div class="helper mt-3">
          <a href="" class="link"><i class="icon-help"></i>{{ $t('myAccount.vouchers.helpLink') }}</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    data: function() {
      return {
        vouchers: [],
        page: undefined
      }
    },
    computed: {
      usedVouchers () {
        return this.vouchers.filter(voucher => !this._.isNil(voucher.used_at))
      },
      activeVouchers () {
        return this.vouchers.filter(voucher => this._.isNil(voucher.used_at))
      }
    },
    mounted () {
      this.getPageHead().then((res) => {
        this.page = res
      })
      this.$api.getVouchers().then(response => {
        this.vouchers = this._.get(response, 'data.data');
      })
    },
    head() {
      var meta = [
        { hid: 'og:title', property: 'og:title', content: this.page?.title },
        { hid: 'og:type', property: 'og:type', content: 'website'}
      ];
      if(this.page?.meta_description) {
        meta.push({ hid: 'description', name: 'description', content: this.page?.meta_description })
        meta.push({ hid: 'og:description', property: 'og:description', content: this.page?.meta_description })
      }
      if(this.page?.og_image) {
        meta.push({ hid: 'og:image', property: 'og:image', content: this.page?.og_image })
      }
      return {
        title: this.page?.title,
        meta: meta
      }
    }
  };
</script>
