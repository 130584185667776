<template>
  <hooper :settings="hooperSettings">
    <slide v-for="(slide, indx) in slides" :key="indx" :index="indx">
      {{ slide }}
    </slide>
    <hooper-navigation slot="hooper-addons"></hooper-navigation>
  </hooper>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation
  },
  data(){ 
    return {
      slides:[1,2,3,4,5,6,7,8,9,10],
      hooperSettings: {
        itemsToShow: 4,
        centerMode: false,
        autoPlay:true,
        infiniteScroll:true,
        itemsToSlide:2,
        playSpeed:3000,
        transition:1000,
        breakpoints: {
          991: {
            itemsToShow: 3,
            pagination: 'fraction'
          },
          768: {
            itemsToShow: 2,
            pagination: 'fraction'
          }
        }
      }
    };
  }
};
//https://baianat.github.io/hooper/getting-started.html
</script>