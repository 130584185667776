<template>
  <div v-if="selectedItem" :class="['lang-drop', dropdownOpened ? 'active' : '']">
    <button class="btn outline dropdown-toggle" type="button" @click="dropdownOpened = !dropdownOpened">
      <img :src="'/flags/' + selectedItem.lang + '.svg'" class="active-lang" />
      {{ selectedItem.text }}
    </button>
    <ul class="drop-block">
      <template v-for="(item, index) in items">
        <li v-if="item.lang !== selectedItem.lang" :key="index" :data-lang="item.lang" @click="changeLanguage(item)">
          <img :src="'/flags/' + item.lang + '.svg'" />
          {{ item.text }}
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
  import {changeLanguage} from "../../plugins/i18n";
  export default {
    data:function(){
      return{
        dropdownOpened: false,
        selectedItem: this._.get(this._.get(this.$store.state, 'lang.localesMapping', {}).filter(item => item.lang === this.$store.state.lang.locale), '0', undefined),
        items: this._.get(this.$store.state, 'lang.localesMapping', [])
      }
    },
    watch: {
      '$route' () {
        this.dropdownOpened = false
      }
    },
    methods: {
      changeLanguage(item){
        this.selectedItem = item;
        this.dropdownOpened = false;
        changeLanguage(this, item.lang, this.$axios)
      }
    }
  }
</script>
