<template>
  <div class="notification-btn-group">
    <b-btn v-if="!onlyDelete && showSeen" class="icon outline icon-visibility" @click="markAsSeen"><span class="txt">{{ $t('btn.seen') }}</span></b-btn>
    <b-btn v-if="!onlyDelete && showArchived" class="icon icon-archive" @click="markAsArchived"><span class="txt">{{ $t('btn.archive') }}</span></b-btn>
    <b-btn class="icon icon-delete" @click="markAsDeleted"><span class="txt">{{ $t('btn.delete') }}</span></b-btn>
  </div>
</template>
<script>
export default {
  props: {
    showSeen: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    showArchived: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    onlyDelete: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  methods: {
    markAsSeen() {
      this.$emit('markAsSeen')
    },
    markAsArchived() {
      this.$emit('markAsArchived')
    },
    markAsDeleted() {
      this.$emit('markAsDeleted')
    }
  }
}
</script>
