<template>
  <b-row>
    <b-col class="my-account vouchers notifications">
      <div class="account-card">
        <h3 class="block-title text-center">{{ $t('header.welcome') }} {{ $auth.user.client_name || $auth.user.name || '' }}</h3>
        <h5 class="block-subtitle">{{ $t('myAccount.notifications.subTitle') }}</h5>
        <b-tabs content-class="">
          <b-tab active>
            <template v-slot:title>
              {{ $t('myAccount.notifications.activeNotifications') }} <span>{{ newNotifications.length }}</span>
            </template>
            <NotificationManager
              v-if="newNotifications.length"
              @toggleCheckboxes="toggleNewNotificationsCheckboxes"
              @markAsSeen="markSeenNewNotifications"
              @markAsArchived="markArhivedNewNotifications"
              @markAsDeleted="markDeletedNewNotifications"
            />
            <ul class="tab-list voucher voucher-active notification" :data-notifications="$t('myAccount.notifications.Empty.New')">
              <li v-for="(notification, index) in newNotifications" :key="index">
                <Notification
                  :notification="notification"
                  :mark-seen="$options.methods.markSeen"
                  :mark-archived="$options.methods.markArchived"
                  :mark-deleted="$options.methods.markDeleted"
                  @checkboxChange="notificationsCheckboxChange($event, notification.id)"
                  @triggerReload="triggerReload"
                ></Notification>
              </li>
            </ul>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              {{ $t('myAccount.notifications.arhivedNotifications') }} <span>{{ archivedNotifications.length }}</span>
            </template>
            <NotificationManager
              v-if="archivedNotifications.length"
              :only-delete="true"
              @toggleCheckboxes="toggleArchivedNotificationsCheckboxes"
              @markAsDeleted="markDeletedArchivedNotifications"
            />
            <ul class="tab-list voucher notification" :data-notifications="$t('myAccount.notifications.Empty.Arhived')">
              <li v-for="(notification, index) in archivedNotifications" :key="index">
                <Notification
                  :notification="notification"
                  :mark-deleted="$options.methods.markDeleted"
                  @checkboxChange="notificationsCheckboxChange($event, notification.id)"
                  @triggerReload="triggerReload"
                ></Notification>
              </li>
            </ul>
          </b-tab>
        </b-tabs>
        <div class="helper mt-3">
          <a href="#!" class="link"><i class="icon-help"></i>{{ $t('myAccount.notifications.helpLink') }}</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    data: function() {
      return {
        notifications: [],
        page: undefined
      }
    },
    computed: {
      archivedNotifications () {
        return this.notifications
            .filter(notification => !this._.isNil(notification.archived_at))
      },
      newNotifications () {
        return this.notifications
            .filter(notification => this._.isNil(notification.archived_at))
      }
    },
    mounted () {
      this.getPageHead().then((res) => {
        this.page = res
      })
      this.loadNotifications()
    },
    methods: {
      loadNotifications() {
        return this.$api.getNotifications().then(response => {
          this.notifications = this._.get(response, 'data.data')
            .map((notification) => {
                notification.checked = false
                return notification
            });
        })
      },
      notificationsCheckboxChange(val, id) {
        let index = this.notifications.findIndex((notification) => notification.id === id)
        if (index >= 0) {
            this.notifications[index].checked = val
        }
      },
      toggleNewNotificationsCheckboxes(val) {
        this.newNotifications.map((notification) => {
          notification.checked = val
        })
      },
      toggleArchivedNotificationsCheckboxes(val) {
        this.archivedNotifications.map((notification) => {
          notification.checked = val
        })
      },
      markSeen (notificationId, status = true) {
        return this.$api.updateNotification(notificationId, {
          read_at: status
        })
      },
      markSeenMultiple (ids, status = true) {
        return this.$api.updateMultipleNotifications({
            read_at: status,
            ids: ids.join(',')
        }).then(() => {
          this.showSuccess(this.$t('general.messages.success'))
          this.loadNotifications()
        }).catch(() => {
          this.showError(this.$t('general.messages.error'))
        })
      },
      markArchived (notificationId, status = true) {
        return this.$api.updateNotification(notificationId, {
          archived_at: status
        })
      },
      markArchivedMultiple (ids, status = true) {
        return this.$api.updateMultipleNotifications({
          archived_at: status,
          ids: ids.join(',')
        }).then(() => {
          this.showSuccess(this.$t('general.messages.success'))
          this.loadNotifications()
        }).catch(() => {
          this.showError(this.$t('general.messages.error'))
        })
      },
      markDeleted (notificationId) {
        return this.$api.deleteNotification(notificationId)
      },
      markDeletedMultiple (ids) {
        return this.$api.deleteMultipleNotifications({
          ids: ids
        }).then(() => {
            this.showSuccess(this.$t('general.messages.success'))
            this.loadNotifications()
        }).catch(() => {
            this.showError(this.$t('general.messages.error'))
        })
      },
      triggerReload () {
        this.loadNotifications()
      },
      markSeenNewNotifications() {
        let ids = this.newNotifications
            .filter((notification) => notification.checked === true)
            .map((notification) => notification.id)
        this.markSeenMultiple(ids)
      },
      markArhivedNewNotifications() {
        let ids = this.newNotifications
            .filter((notification) => notification.checked === true)
            .map((notification) => notification.id)
        this.markArchivedMultiple(ids)
      },
      markDeletedNewNotifications() {
        let ids = this.newNotifications
            .filter((notification) => notification.checked === true)
            .map((notification) => notification.id)
        this.markDeletedMultiple(ids)
      },
      markDeletedArchivedNotifications() {
        let ids = this.archivedNotifications
            .filter((notification) => notification.checked === true)
            .map((notification) => notification.id)
        this.markDeletedMultiple(ids)
      }
    },
    head() {
      var meta = [
        { hid: 'og:title', property: 'og:title', content: this.page?.title },
        { hid: 'og:type', property: 'og:type', content: 'website'}
      ];
      if(this.page?.meta_description) {
        meta.push({ hid: 'description', name: 'description', content: this.page?.meta_description })
        meta.push({ hid: 'og:description', property: 'og:description', content: this.page?.meta_description })
      }
      if(this.page?.og_image) {
        meta.push({ hid: 'og:image', property: 'og:image', content: this.page?.og_image })
      }
      return {
        title: this.page?.title,
        meta: meta
      }
    }
  };
</script>
