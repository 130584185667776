<template>
  <div class="section-locations homepage">
    <b-container>
      <h1 class="pageTitle">{{ $t('homeSelect.pageTitle') }}</h1>
      <div :class="['location-wrap', locationOpened ? 'opened': null]">
        <b-col>
          <b-row>
            <b-col class="col-12 pb-3 d-flex d-sm-none align-items-center justify-content-start">
              <h5 class="title" @click="locationOpened = !locationOpened">{{ $t('chooseLocation') }}:</h5>
              <div class="ml-auto d-flex d-sm-none">
                <button type="button" name="locations" class="ml-auto btn btn-location" @click="searchWithGeolocation">
                  <i class="icon-location"></i>
                </button>
                <button type="button" name="locations" class="ml-1 btn btn-location" @click="locationOpened = !locationOpened">
                  <i class="icon-arrow-up"></i>
                </button>
              </div>
            </b-col>

            <b-col>
              <div class="mobile-wrap">
                <b-row>
                  <b-col md="6" lg="3" xl="3">
                    <div class="form-group select">
                      <select v-model="countyId" class="form-control">
                        <option value="0">Judet</option>
                        <option v-for="(county, cIndex) in availableCounties" :key="cIndex" :value="county.id">{{ county.name }}</option>
                      </select>
                      <i class="icon-arrow-up"></i>
                    </div>
                  </b-col>

                  <b-col md="6" lg="3" xl="4">
                    <div :class="['form-group select', availableCities.length < 1 ? 'disabled' : null ]">
                      <select v-model="cityId" class="form-control">
                        <option value="0">Localitate</option>
                        <option v-for="(city, cIndex) in availableCities" :key="cIndex" :value="city.id">{{ city.name }}</option>
                      </select>
                      <i class="icon-arrow-up"></i>
                    </div>
                  </b-col>

                  <b-col class="d-flex buttons">
                    <div class="form-group auto">
                      <button :disabled="!canSearchLocation" type="button" class="btn submit text-left" @click="goToResultsPage">{{ $t('homeSelect.searhForm.search') }}<i class="ico icon-search"></i></button>
                    </div>
                    <div class="form-group auto ml-sm-4 flex-grow-1 d-flex">
                      <button type="button" class="btn submit flex-grow-1 text-left" @click="searchWithGeolocation">{{ $t('homeSelect.searhForm.nearby') }}<i class="icon-menu"></i></button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </div>
    </b-container>
  </div>
</template>
<script>
  import LocationSelector from "./LocationSelector";
  export default {
    extends: LocationSelector,
    computed: {
      canSearchLocation () {
        return parseInt(this.countyId) > 0 && parseInt(this.cityId) > 0
      }
    },
    methods: {
      goToResultsPage () {
        let foundCounty = this.availableCounties.filter((county) => {
          return county.id === this.countyId
        })
        let foundCity = this.availableCities.filter((city) => {
          return city.id === this.cityId
        })
        if(foundCounty.length && foundCity.length) {
          this.$router.replace({
            name: 'restaurants-in-city',
            params: {
              county: this.slugify(this._.get(foundCounty, '0.name')),
              city: this.slugify(this._.get(foundCity, '0.name'))
            }
          })
        }
      }
    }
  };
</script>
