<template>
  <section class="section-locations">
    <b-container>
      <form accept-charset="utf-8" method="post" action="" autocomplete="off">
        <div :class="['location-wrap', locationOpened ? 'opened': null]">
          <b-col>
            <b-row>
              <b-col class="col-12 pb-3 d-flex d-sm-none align-items-center justify-content-start justify-content-md-end">
                <h5 class="title">{{ $t('chooseLocation') }}:</h5>
                <div class="ml-auto d-flex d-sm-none">
                  <button type="button" name="locations" class="btn btn-location" @click="searchWithGeolocation">
                    <i class="icon-location"></i>
                  </button>
                  <button type="button" name="locations" class="ml-1 btn btn-location" @click="locationOpened = !locationOpened">
                    <i class="icon-arrow-up"></i>
                  </button>
                </div>
              </b-col>

              <b-col>
                <div class="mobile-wrap">
                  <b-row>
                    <b-col md="6" lg="3" xl="3">
                      <div class="form-group select">
                        <select v-model="countyId" class="form-control">
                          <option value="0">Judet</option>
                          <option v-for="(county, cIndex) in availableCounties" :key="cIndex" :value="county.id">{{ county.name }}</option>
                        </select>
                        <i class="icon-arrow-up"></i>
                      </div>
                    </b-col>

                    <b-col md="6" lg="3" xl="4">
                      <div :class="['form-group select', availableCities.length < 1 ? 'disabled' : null ]">
                        <select v-model="cityId" class="form-control">
                          <option value="0">Localitate</option>
                          <option v-for="(city, cIndex) in availableCities" :key="cIndex" :value="city.id">{{ city.name }}</option>
                        </select>
                        <i class="icon-arrow-up"></i>
                      </div>
                    </b-col>

                    <b-col class="d-flex buttons mobile-100">
                      <div class="form-group auto flex-grow-1 flex-sm-grow-0 d-flex">
                        <button :disabled="!canSaveLocation" type="button" class="btn submit flex-grow-1 text-left" @click="saveLocation">{{ $t('saveLocation') }}<i class="icon-ok"></i></button>
                      </div>
                      <div class="form-group auto ml-sm-4 flex-grow-1 d-flex">
                        <button type="button" class="btn submit aproape text-left" @click="searchWithGeolocation">{{ $t('homeSelect.searhForm.nearby') }}<i class="icon-menu"></i></button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </form>
    </b-container>
  </section>
</template>
<script>
  export default {
    props: {
      restaurantFilters: {
        type: Object,
        default: function () {
          return {
            county:undefined,
            city: undefined
          }
        }
      }
    },
    data:function(){
      return {
        locationOpened: false,
        availableCounties: [],
        availableCities: [],
        countyId:'0',
        cityId:'0',
        selectedCity: undefined,
        selectedCounty: undefined 
      }
    },
    computed: {
      canSaveLocation () {
        return parseInt(this.countyId) > 0
      }
    },
    watch: {
      countyId: {
        handler (val) {
          if (parseInt(val) === 0 || val === 'undefined' ) {
            this.countyId = '0'
            this.cityId = '0'
          }
          this.getCitiesByCountyId(val).catch((e) => {
            this.availableCities = []
          })
          this.$emit('change', {
            countyId: parseInt(this.countyId) > 0 ? parseInt(this.countyId) : undefined,
            cityId: parseInt(this.cityId) > 0 ? parseInt(this.cityId) : undefined,
            county: this.selectedCounty,
            city: this.selectedCity
          })
        }
      },
      cityId: {
        handler (val) {
          this.$emit('change', {
            countyId: parseInt(this.countyId) > 0 ? parseInt(this.countyId) : undefined,
            cityId: parseInt(this.cityId) > 0 ? parseInt(this.cityId) : undefined,
            county: this.selectedCounty,
            city: this.selectedCity
          })
        }
      }
    },
    mounted() {
      this.countyId = this.$store.getters['location/countyId'] || '0'
      this.cityId = this.$store.getters['location/cityId'] || '0'
      let countyToFind = this._.get(this.restaurantFilters, 'county')
      let cityToFind = this._.get(this.restaurantFilters, 'city')
      this.$api.getCounties([], [], [], {}, true).then((counties) => {
        this.availableCounties = this._.get(counties, 'data.data', [])
        if(countyToFind) {
          let countyFound = this.availableCounties.filter((county) => {
            return this.slugify(county.name) === countyToFind
          })
          if (countyFound.length) {
            this.countyId = this._.get(countyFound, '0.id', '0')
            this.selectedCounty = this._.get(countyFound, '0.name')
          }
        }
      }).then(() => {
        if (this.countyId !== undefined) {
          this.getCitiesByCountyId(this.countyId).then(() => {
            if(cityToFind) {
              let cityFound = this.availableCities.filter((city) => {
                return this.slugify(city.name) === cityToFind
              })
              if (cityFound.length) {
                this.cityId = this._.get(cityFound, '0.id')
                this.selectedCity = this._.get(cityFound, '0.name')
              }
            }
          }).catch((e) => {
            this.availableCities = []
          })
        } else {
          this.availableCities = []
        }
      })
    },
    methods: {
      saveLocation() {
        this.$store.dispatch('location/setLocation', {
          countyId: this.countyId,
          cityId: this.cityId
        })
      },
      searchWithGeolocation () {
        this.getDeviceCoords().then((coords) => {
          if(['restaurants', 'restaurants-in-county', 'restaurants-in-city'].indexOf(this.$route.name) >= 0) {
            this.$root.$emit('geolocationDone')
          } else {
            this.$router.replace({ name: 'restaurants' })
          }
        }).catch((err) => {
          this.showError(this.$t('general.messages.geolocation_error'))
        }).finally(() => {
          this.locationOpened = false
        })
      },
      getCitiesByCountyId (countyId) {
        return new Promise((resolve, reject) => {
          if( parseInt(countyId) < 1 ) {
            reject('No countyId')
          } else {
            return this.$api.getCitiesByCountyId(countyId, true).then((cities) => {
              this.availableCities = this._.get(cities, 'data', [])
              if (this.availableCities.length < 1) {
                this.cityId = '0'
              }
              resolve(true)
            })
          }
        })
      }
    }
  };
</script>
