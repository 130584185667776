<template>
  <section class="card max-424 on-backdrop overflow-y-auto on-backdrop loginform registerForm">
    <b-btn variant="outline" class="closer icon-close" @click="back"></b-btn>
    <h2 class="title">
      {{ $t('registerForm.title') }}
    </h2>
    <form>
      <div class="form-group with-ico">
        <input v-model="user.phone" :placeholder="($t('registerForm.input.phone'))" name="phone" type="text" class="form-control" />
        <i class="icoAbs icon-phone" />
        <template v-if="errors.phone">
          <p v-for="(error, index) in errors.phone" :key="index" class="text-danger">{{ error }}</p>
        </template>
      </div>
      <div class="form-group with-ico">
        <input v-model="user.email" :placeholder="($t('registerForm.input.email'))" name="email" type="text" class="form-control" />
        <i class="icoAbs icon-email" />
        <template v-if="errors.email">
          <p v-for="(error, index) in errors.email" :key="index" class="text-danger">{{ error }}</p>
        </template>
      </div>
      <div :class="['form-group pass-swithcer', toggleVisibility ? 'switched' : '']">
        <input v-model="user.password" :placeholder="($t('registerForm.input.password'))" type="password" name="password" class="form-control ipassword" />
        <input v-model="user.password" :placeholder="($t('registerForm.input.password'))" type="text" name="password" class="form-control itext" />
        <b-btn class="swither" @click="toggleVisibility = !toggleVisibility"><i class="icon-visibility"></i></b-btn>
        <template v-if="errors.password">
          <p v-for="(error, index) in errors.password" :key="index" class="text-danger">{{ error }}</p>
        </template>
      </div>
      <div :class="['form-group pass-swithcer', toggleVisibility ? 'switched' : '']">
        <input v-model="user.confirm_password" :placeholder="($t('registerForm.input.passwordConfirm'))" type="password" name="confirm_password" class="form-control ipassword" />
        <input v-model="user.confirm_password" :placeholder="($t('registerForm.input.passwordConfirm'))" type="text" name="confirm_password" class="form-control itext" />
        <template v-if="errors.confirm_password">
          <p v-for="(error, index) in errors.confirm_password" :key="index" class="text-danger">{{ error }}</p>
        </template>
      </div>
      <div class="form-group">
        <b-btn :class="['register fullwidth uppercase', !errors.length && !isLoading ? '' : 'disabled']" variant="primary" type="submit" @click.prevent="register">
          {{ $t('registerForm.registerText') }}
          <i class="icon-ok" />
        </b-btn>
      </div>
      <div class="form-group text-center py-2">
        <span class="lines">{{ $t('registerForm.alternativeRegister') }}</span>
      </div>
      <b-row>
        <b-col xs="12" sm="6" class="form-group pr-sm-2">
          <b-btn class="fullwidth btn-fb" type="submit" @click.prevent="loginWithFacebook">
            <i class="icon-facebook" />
            Facebook
          </b-btn>
        </b-col>
        <b-col xs="12" sm="6" class="form-group pl-sm-2">
          <b-btn class="fullwidth btn-ggl" type="submit" @click.prevent="loginWithGoogle">
            <i class="icon-google" />
            Google
          </b-btn>
        </b-col>
      </b-row>
    </form>
  </section>
</template>
<script>
  export default {
    data() {
      return {
        toggleVisibility: false,
        isLoading: false,
        errors: {},
        user: {
          phone: '',
          email: '',
          password: '',
          confirm_password: ''
        }
      }
    },
    methods: {
      async register() {
        let that = this;
        this.isLoading = true
        this.errors = {};
        await this.$api.register(
          this.user.phone,
          this.user.email,
          this.user.password,
          this.user.confirm_password
        ).then(response => {
          this.showSuccess(response?.data?.success?.message, 25000)
        }).catch(err => {
          if(err.response.status === 422)
          {
            that.errors = err.response.data.error
          } else {
            console.error(err)
          }
        }).finally(() => {
          this.isLoading = false
        })
        //this.$router.replace('/')
      },
      async loginWithFacebook () {
        this.$auth.loginWith('laravel_facebook')
      },
      async loginWithGoogle () {
        this.$auth.loginWith('laravel_google')
      }
    }

  }
</script>
