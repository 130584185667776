<template>
  <b-row v-if="show">
    <b-col class="my-account abonare">
      <div class="account-card text-center">
        <p>{{ $t('onesignal.text') }}</p>
        <b-btn class="icon-email" @click="subscribeUser">Abonare</b-btn>
        <!--<div class="onesignal-customlink-container my-2"></div>-->
        <p v-if="onlyFirstTime"><br>{{ $t('onesignal.unsubscribe') }}</p>
      </div>
    </b-col>
  </b-row>
</template>
<script>
  import Cookie from 'js-cookie'
  export default {
    props: {
      onlyFirstTime: {
        type: Boolean,
        default: function () {
          return false
        }
      }
    },
    data: function () {
      return {
        show: false
      }
    },
    watch: {
      onlyFirstTime: {
        handler (val) {
          this.init()
        }
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init () {
        this.isRegisteredForOnesignal().then((isEnabled) => {
          if(isEnabled) return
          if(this.onlyFirstTime) {
            this.show = true
          } else {
            this.show = true
          }
        }).catch((err) => {
          console.error(err.toString())
        })
      },
      subscribeUser () {
        this.requestOnesignalNotifications()
      }
    }
  }
</script>
