<template>
  <ul class="nav mx-auto ml-lg-auto mr-lg-0 justify-content-start justify-content-lg-end navigation">
    <li v-if="isDesktop">
      <a :class="['menu-drop', dropdownOpened ? 'active' : '']" @click="dropdownOpened = !dropdownOpened">
        {{ $t('header.nav.adressessAndOrders.link') }}
        <span class="icon-arrow-up"></span>
      </a>
      <ul class="drop-menu">
        <li>
          <nuxt-link :to="{ name: 'my-account-subpage', params: { slug: 'adresele-mele' } }">
            <i class="ico icon-room"></i>
            {{ $t('header.nav.adressessAndOrders.submenu.myAddresses') }}
          </nuxt-link>
        </li>
        <li>
          <nuxt-link :to="{ name: 'my-account-subpage', params: { slug: 'istoric-comenzi' } }">
            <i class="ico icon-menu"></i>
            {{ $t('header.nav.adressessAndOrders.submenu.myOrders') }}
          </nuxt-link>
        </li>
      </ul>
    </li>
    <li v-if="!isDesktop">
      <nuxt-link :to="{ name: 'my-account-subpage', params: { slug: 'adresele-mele' } }">
        <i class="ico icon-room"></i>
        {{ $t('header.nav.adressessAndOrders.submenu.myAddresses') }}
      </nuxt-link>
    </li>
    <li v-if="!isDesktop">
      <nuxt-link :to="{ name: 'my-account-subpage', params: { slug: 'istoric-comenzi' } }">
        <i class="ico icon-menu"></i>
        {{ $t('header.nav.adressessAndOrders.submenu.myOrders') }}
      </nuxt-link>
    </li>
    <!--<li>
      <nuxt-link :to="{ name: 'my-account-subpage', params: { slug: 'vouchere' } }">
        <span class="icon-voucher"><span v-if="vouchere" class="item">{{ vouchere }}</span></span>
        {{ $t('header.nav.vouchers') }}
      </nuxt-link>
    </li>-->
    <li>
      <nuxt-link :to="{ name: 'my-account-subpage', params: { slug: 'notificari' } }">
        <span class="icon-email"><span v-if="alerts" class="item">{{ alerts }}</span></span>
        {{ $t('header.nav.messages') }}
      </nuxt-link>
    </li>
    <li>
      <nuxt-link :to="{ name: 'faq' }">
        <span class="icon-help"></span>
        {{ $t('header.nav.help') }}
      </nuxt-link>
    </li>
  </ul>
</template>
<script>
  export default {
    data:function(){
      return {
        dropdownOpened: false,
        isDesktop: typeof window !== 'undefined' ? window.innerWidth > 991 : true,
        vouchere: this._.get(this.$auth.user, 'active_vouchers_count', 0),
        alerts: this._.get(this.$auth.user, 'active_notifications_count', 0)
      }
    },
    watch: {
      '$route' () {
        this.dropdownOpened = false
      }
    }
  }
</script>
