<template>
  <section class="card max-424 overflow-y-auto on-backdrop loginform">
    <b-btn variant="outline" class="closer icon-close" @click="back"></b-btn>
    <h2 class="title">
      {{ $t('resetForm.title') }}
    </h2>
    <p v-if="!isResetting">{{ $t('resetForm.instruction') }}</p>
    <p v-else>{{ $t('resetForm.instructionStep2') }}</p>
    <form>
      <div class="form-group">
        <input v-model="user.email" :placeholder="($t('resetForm.input.email'))" type="email" name="email" class="form-control" />
      </div>
      <div v-if="isResetting" class="form-group">
        <input v-model="user.password" :placeholder="($t('resetForm.input.password'))" type="password" name="password" class="form-control" />
      </div>
      <div v-if="isResetting" class="form-group">
        <input v-model="user.confirmPassword" :placeholder="($t('resetForm.input.confirmPassword'))" type="password" name="confirmPassword" class="form-control" />
      </div>
      <div class="form-group">
        <p v-if="error" class="text-danger">{{ error }}</p>
        <b-btn :class="['login fullwidth uppercase', error ? 'disabled' : '']" variant="primary" type="submit" @click.prevent="recover">
          {{ $t('resetForm.recoverButton') }}
          <i class="icon-ok" />
        </b-btn>
      </div>
    </form>
  </section>
</template>

<script>
  import {parseQuery} from "../../.nuxt/auth/utilities";
  export default {
    data() {
      return {
        error: undefined,
        token: undefined,
        user: {
          email: '',
          password: '',
          confirmPassword: ''
        }
      }
    },
    computed: {
      isResetting ()
      {
        return this.token !== undefined
      }
    },
    mounted () {
      const params = parseQuery(this.$route.hash.substr(1))
        if (params.token) {
          this.token = params.token
      }
    },
    methods: {
      async recover() {
        let that = this;
        if (this.isResetting) {
          await this.$api.passwordReset(this.token, this.user.email, this.user.password, this.user.confirmPassword).then(response => {
            this.showSuccess(this._.get(response, 'data.messages'))
            this.$router.replace({ name: 'login' })
          }).catch(err => {
            this.showError(Object.values(this._.get(err.response, 'data.errors', {})))
          })
        } else {
          await this.$api.passwordForgot(this.user.email).then(response => {
            this.showSuccess(this._.get(response, 'data.message'))
          }).catch(err => {
            this.showError(Object.values(this._.get(err.response, 'data.errors', {})))
          })
        }
      }
    }
  }
</script>
